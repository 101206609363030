function bigIntToByteArray(bigInt) {
    const hex = bigInt.toString(16);
    const byteArray = new Uint8Array(Math.ceil(hex.length / 2));
    for (let i = 0; i < byteArray.length; i++) {
        const start = Math.max(0, hex.length - (i + 1) * 2);
        const end = hex.length - i * 2;
        byteArray[byteArray.length - 1 - i] = parseInt(hex.slice(start, end), 16);
    }
    return byteArray;
}

function modInverse(a, m) {
    let [old_r, r] = [BigInt(a), BigInt(m)];
    let [old_s, s] = [1n, 0n];
    let [old_t, t] = [0n, 1n];
    while (r !== 0n) {
        const quotient = old_r / r;
        [old_r, r] = [r, old_r - quotient * r];
        [old_s, s] = [s, old_s - quotient * s];
        [old_t, t] = [t, old_t - quotient * t];
    }
    return old_s < 0n ? old_s + m : old_s;
}

function bigIntToBase64Url(bigInt) {
    const hex = bigInt.toString(16);
    const buffer = new Uint8Array(Math.ceil(hex.length / 2));
    for (let i = 0; i < buffer.length; i++) {
        const start = Math.max(0, hex.length - (i + 1) * 2);
        const end = hex.length - i * 2;
        buffer[buffer.length - 1 - i] = parseInt(hex.slice(start, end), 16);
    }
    return btoa(String.fromCharCode(...buffer))
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_');
}

// Modular exponentiation
function modPow(base, exponent, modulus) {
    let result = 1n;
    base = base % modulus;
    while (exponent > 0n) {
        if (exponent % 2n === 1n) {
            result = (result * base) % modulus;
        }
        base = (base * base) % modulus;
        exponent = exponent >> 1n;
    }
    return result;
}

// Main RSA generation function
export async function generateRSAFromPBKDF2(password, salt) {
    async function generateSeed(password, salt) {
        const encoder = new TextEncoder();
        const passwordBuffer = encoder.encode(password);

        const baseKey = await crypto.subtle.importKey(
            'raw',
            passwordBuffer,
            'PBKDF2',
            false,
            ['deriveBits']
        );

        return await crypto.subtle.deriveBits(
            {
                name: 'PBKDF2',
                salt: salt,
                iterations: 100000,
                hash: 'SHA-256'
            },
            baseKey,
            512 // Generate 512 bits
        );
    }

    function isPrime(n, k = 10) {
        if (n <= 1n || n == 4n) return false;
        if (n <= 3n) return true;

        let d = n - 1n;
        let r = 0n;
        while (d % 2n === 0n) {
            d /= 2n;
            r++;
        }

        for (let i = 0; i < k; i++) {
            let a = 2n + BigInt(Math.floor(Math.random() * (Number(n - 2n))));
            let x = modPow(a, d, n);
            if (x === 1n || x === n - 1n) continue;

            let composite = true;
            for (let j = 0n; j < r - 1n; j++) {
                x = modPow(x, 2n, n);
                if (x === n - 1n) {
                    composite = false;
                    break;
                }
            }
            if (composite) return false;
        }
        return true;
    }

    try {
        const seed1 = new Uint8Array(await generateSeed(password, new Uint8Array([...salt, 1])));
        const seed2 = new Uint8Array(await generateSeed(password, new Uint8Array([...salt, 2])));

        let p = BigInt('0x' + Array.from(seed1).map(b => b.toString(16).padStart(2, '0')).join(''));
        let q = BigInt('0x' + Array.from(seed2).map(b => b.toString(16).padStart(2, '0')).join(''));

        p |= (1n << 1023n);
        q |= (1n << 1023n);
        p |= 1n;
        q |= 1n;

        while (!isPrime(p)) p += 2n;
        while (!isPrime(q)) q += 2n;

        const n = p * q;
        const e = 65537n;
        const phi = (p - 1n) * (q - 1n);
        const d = modInverse(e, phi);

        const publicKeyData = {
            kty: 'RSA',
            n: bigIntToBase64Url(n),
            e: bigIntToBase64Url(e),
            alg: 'RSA-OAEP-256',
            ext: true
        };

        const privateKeyData = {
            ...publicKeyData,
            d: bigIntToBase64Url(d),
            p: bigIntToBase64Url(p),
            q: bigIntToBase64Url(q),
            dp: bigIntToBase64Url(d % (p - 1n)),
            dq: bigIntToBase64Url(d % (q - 1n)),
            qi: bigIntToBase64Url(modInverse(q, p))
        };

        const publicKey = await crypto.subtle.importKey(
            'jwk',
            publicKeyData,
            { name: 'RSA-OAEP', hash: 'SHA-256' },
            true,
            ['encrypt']
        );

        const privateKey = await crypto.subtle.importKey(
            'jwk',
            privateKeyData,
            { name: 'RSA-OAEP', hash: 'SHA-256' },
            true,
            ['decrypt']
        );

        return { publicKey, privateKey };
    } catch (error) {
        throw new Error('Failed to generate RSA key pair: ' + error.message);
    }
}

export async function importPublicKeyFromPem(pem) {
    // Step 1: Remove PEM headers and line breaks
    const pemHeader = "-----BEGIN PUBLIC KEY-----";
    const pemFooter = "-----END PUBLIC KEY-----";
    const pemContents = pem
        .replace(pemHeader, "")
        .replace(pemFooter, "")
        .replace(/\s+/g, ""); // Remove line breaks and whitespace

    // Step 2: Decode the base64 string to get the binary DER encoding
    const binaryDer = Uint8Array.from(atob(pemContents), c => c.charCodeAt(0));

    // Step 3: Import the binary DER as a CryptoKey object
    return await crypto.subtle.importKey(
        "spki",                          // Key format
        binaryDer.buffer,                // Key data
        {  name: "RSA-OAEP", hash: "SHA-256" },
        true,                            // Whether the key is extractable
        ["encrypt"]                      // Key usages
    );
}

export async function importPrivateKeyFromPem(pem) {
    // Step 1: Remove PEM headers and line breaks
    const pemHeader = "-----BEGIN PRIVATE KEY-----";
    const pemFooter = "-----END PRIVATE KEY-----";
    const pemContents = pem
        .replace(pemHeader, "")
        .replace(pemFooter, "")
        .replace(/\s+/g, ""); // Remove line breaks and whitespace

    // Step 2: Decode the base64 string to binary DER format
    const binaryDer = Uint8Array.from(atob(pemContents), c => c.charCodeAt(0));

    // Step 3: Import the binary DER as a CryptoKey object
    return await crypto.subtle.importKey(
        "pkcs8",                        // Key format for private key
        binaryDer.buffer,               // Key data
        {                               // Algorithm parameters
            name: "RSA-OAEP",
            hash: "SHA-256",
        },
        true,                           // Whether the key is extractable
        ["decrypt"]                     // Key usages
    );
}


export async function encryptWithPublicKey(publicKey, message) {
    const encoder = new TextEncoder();
    const encodedMessage = encoder.encode(message);
    return await crypto.subtle.encrypt(
        { name: "RSA-OAEP" },
        publicKey,
        encodedMessage
    );
}

export async function decryptWithPrivateKey(privateKey, encryptedMessage) {
    const decryptedBuffer = await crypto.subtle.decrypt(
        { name: "RSA-OAEP" },
        privateKey,
        encryptedMessage
    );
    return new TextDecoder().decode(decryptedBuffer);
}

export async function exportCryptoKey(cryptoKey, isPrivate = false) {
    try {
        const jwk = await crypto.subtle.exportKey('jwk', cryptoKey);
        const format = isPrivate ? 'pkcs8' : 'spki';
        const binaryKey = await crypto.subtle.exportKey(format, cryptoKey);
        const base64Key = btoa(String.fromCharCode(...new Uint8Array(binaryKey)));
        const pemHeader = isPrivate ? '-----BEGIN PRIVATE KEY-----\n' : '-----BEGIN PUBLIC KEY-----\n';
        const pemFooter = isPrivate ? '\n-----END PRIVATE KEY-----' : '\n-----END PUBLIC KEY-----';
        const pem = pemHeader + base64Key.match(/.{1,64}/g).join('\n') + pemFooter;

        return { jwk, binary: binaryKey, base64: base64Key, pem };
    } catch (error) {
        throw new Error('Failed to export key: ' + error.message);
    }
}
