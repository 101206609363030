import axios from "axios";
import { defineStore } from "pinia";

export const useUserDataKeyStore = defineStore("UserDataKeyStore", {
    state: () => ({
        user_data_key: null,
        marked_as_lost: false,
    }),
    getters: {
        getUserDataKey: (state) => {
            return state.user_data_key || null;
        },
        is_data_key_lost: (state) => {
            return state.marked_as_lost;
        }
    },
    actions: {
        setUserDataKey(value) {
            this.user_data_key = value;
        },

        setDataKeyMarkAsLost(value) {
            this.marked_as_lost = value;
        },

        async fetchUserDataKey(profile) {
            const {data} = await axios.get(`/user-profiles/${profile.id}/user-data-key`);
            if (data?.data) {
                console.log('Response: ' + data.data);
                this.user_data_key = data.data.user_data_key;
                this.marked_as_lost = data.data.marked_as_lost;
            }
        },
    },
});
